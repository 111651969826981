import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Tag } from "wmk-tag";
import { GatsbyImageData } from "wmk-image";
import { IndexedSearch, SearchIndex, SearchIndexQuery } from "wmk-search";
import { ResultIcon } from "../../components/Layout/Header/MainBar/Search";
import { WmkLink } from "wmk-link";
import { tagify as tagifyVideo } from "../../classes/YoutubeVideo";
import { tagify as tagifyPdf } from "../../classes/PdfAsset";
import { Type } from "../../components/ui/Typography";
import { colors } from "../../vars/palette";
import { getTableBg } from "../../components/ui/Events/Events";
import styled from "styled-components";
import DipraBreadcrumbs from "../../components/Layout/Breadcrumbs";
// import PageHeader from "../components/ui/PageHeader";
import PageHeader from "../../components/ui/PageHeader";
import { WmkSeo } from "wmk-seo";
import { Page } from "../../classes/Page";
import { MetadataFields } from "../../fragments/NodeMetaFields";
import { PageFields } from "../../fragments/NodePageFields";
import PdfCta from "../../components/ui/PdfCta";

const AllResourcesContainer = styled(Container)`
  padding-right: 0;
  padding-left: 0;
  .headline {
    margin-top: 0;
  }
  .results-row {
    padding: 0 10%;
  }
  @media only screen and (max-width: 767px) {
    .results-row {
      padding: 0 3%;
    }
  }
`;

enum CATNAMES {
  ALL = "Filter Categories"
}

interface PdfQuery {
  file: {
    url: string;
  };
  title: string;
  description: string;
}

interface PdfResult extends IndexedSearch {
  nodeType: "pdf";
  node: PdfQuery;
}

interface VideoQuery {
  title: string;
  videoId: string;
  localThumbnail: {
    childImageSharp: {
      gatsbyImageData: GatsbyImageData;
    };
  };
  description: string;
}

interface VideoResult extends IndexedSearch {
  nodeType: "video";
  node: VideoQuery;
}

interface TaggedIndexedSearch extends IndexedSearch {
  node: {
    [key: string]: any;
    tags: Tag[];
  };
}

interface TaggedPdfResult extends TaggedIndexedSearch {
  nodeType: "pdf";
}

interface TaggedVideoResult extends TaggedIndexedSearch {
  nodeType: "video";
}

interface SearchDataQuery extends SearchIndexQuery {
  pdf: {
    edges: {
      node: PdfQuery;
    }[];
  };
  video: {
    edges: {
      node: VideoQuery;
    }[];
  };
}

interface TechResQuery {
  pdf: {
    edges: {
      node: PdfQuery;
    }[];
  };
  video: {
    edges: {
      node: VideoQuery;
    }[];
  };
  site: MetadataFields;
  page: PageFields;
}

const curatedCategories = [
  "planning and design",
  "pipe features",
  "applications",
  "installation",
  "corrosion control",
  "pipe material comparison",
  "asset management",
  "general"
  // "Design",
  // "Pipe Material Comparison",
  // "Case Studies",
  // "Planning",
  // "Installation",
  // "Asset Management",
  // "Applications",
  // "General",
  // "Pipe Features",
  // "Corrosion Control",
  // "Hydraulics",
  // "Tapping",
  // "Guidelines",
  // "Material Comparisons",
  // "Historical"
].map((c) => new Tag(c));

const AllResources = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [titleSearch, setTitleSearch] = useState("");
  const [selectedCategory, setSelectedCategory] = useState<string>(
    CATNAMES.ALL
  );
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [viewWidth, setViewWidth] = useState(0);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const handleResize = () => {
    const winWidth = window.innerWidth;
    setViewWidth(winWidth);
  };

  const searchData: TechResQuery = useStaticQuery(query);
  const indexData: SearchDataQuery = {
    pdf: searchData.pdf,
    video: searchData.video
  };
  const pageData = { page: searchData.page, site: searchData.site };

  // const allPdfs = searchData.allContentfulAsset.edges.map((e) => e.node);
  // const allVideos = searchData.allYoutubeVideo.edges.map((e) => e.node);

  const index = new SearchIndex(indexData);
  index.index.sort((a, b) => a.node.title.localeCompare(b.node.title));

  const taggedIndex: TaggedIndexedSearch[] = index.index.map(
    (result: IndexedSearch) => {
      return {
        nodeType: result.nodeType,
        node: {
          ...result.node,
          tags:
            result.nodeType === "video"
              ? tagifyVideo(result.node.description)
              : tagifyPdf(result.node.shelfSlugs.shelfSlugs)
        }
      };
    }
  );

  const hashedTagIndex = taggedIndex.reduce((hash, tagged) => {
    const tags = tagged.node.tags;
    tags.forEach((t) => {
      if (t.slug !== "") {
        if (!hash[t.slug]) {
          hash[t.slug] = { name: t.name, slug: t.slug, list: [] };
        }
        hash[t.slug]["list"].push(tagged);
      }
    });
    return hash;
  }, {});

  const curatedHashIndex = curatedCategories.reduce((hash, cat) => {
    if (!hash[cat.slug]) {
      hash[cat.slug] = hashedTagIndex[cat.slug];
    }
    return hash;
  }, {});

  // Handle functions are both unused from the previous version of the search

  // const handleTitleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const search = e.target.value;
  //   setTitleSearch(search);
  //   if (search === "") {
  //     setSearchResults([]);
  //   } else {
  //     setSearchResults(
  //       taggedIndex.filter((result) => {
  //         const regex = new RegExp(search, "i");
  //         return result.node.title.match(regex);
  //       })
  //     );
  //   }
  // };

  // const handleCategorySelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
  //   const cat = e.target.value;
  //   setSelectedCategory(cat);
  //   if (cat !== CATNAMES.ALL) {
  //     setSelectedCategories((prev) => {
  //       prev.push(cat);
  //       return [...new Set(...prev)];
  //     });
  //   }
  // };

  // const pageData = useStaticQuery(graphql`
  //   {
  //     site {
  //       ...SiteMetadataFields
  //     }
  //     page: contentfulPage(slug: { eq: "Technical Resources" }) {
  //       ...NodePageFields
  //       ...PageBlockImageCopy
  //       ...PageBlockIconCopy
  //       ...PageBlockIconCard
  //       ...PageBlockTestimonial
  //       ...PageBlockColumns
  //     }
  //   }
  // `);

  const { siteMetadata } = pageData.site;
  const page = new Page(pageData.page);

  return (
    <>
      <WmkSeo.Meta
        siteTitle={siteMetadata.title}
        title={"All Technical Resources"}
        slug="all"
        path={"/technical-resources/"}
        baseUrl={siteMetadata.baseUrl}
        twitterImageUrl={page.image.url("twitterImage")}
        ogImageUrl={page.image.url("ogImage")}
        twitterHandle={siteMetadata.twitter}
        description={page.meta}
      />
      <AllResourcesContainer fluid>
        <DipraBreadcrumbs route={`technical-resources/all`} />
        <PageHeader
          headline="All Technical Resources"
          subtext="Click on any link in the below list to access technical resources in
            downloadable PDF format, or launch an informative video."
        />
        {/* <Row>
        <Col xs={12}>
          <Type.H1 style={{ textAlign: "center" }}>
            All Technical Resources
          </Type.H1>
        </Col>
      </Row> */}
        {/* <Row>
        <Col>
          <Type.SubTitle style={{ textAlign: "center" }}>
            Click on any link in the below list to access technical resources in
            downloadable PDF format, or launch an informative video.
          </Type.SubTitle>
        </Col>
      </Row> */}
        {/* <Row style={{ padding: `1rem 0 0 0` }}>
        <Col>
          <SearchInterface
            categories={[]}
            titleSearch={titleSearch}
            handleTitleSearch={handleTitleSearch}
            selectedCategory={selectedCategory}
            handleCategorySelection={handleCategorySelection}
          />
        </Col>
      </Row> */}
        <Row className="results-row">
          <Col>
            {searchResults.length > 0 ? (
              <SearchResults
                results={searchResults}
                mobile={viewWidth < 767 ? true : false}
              />
            ) : (
              <CuratedDisplay
                selectedCategory={selectedCategory}
                categories={curatedCategories}
                hash={curatedHashIndex}
                mobile={viewWidth < 767 ? true : false}
              />
            )}
          </Col>
        </Row>
        <PdfCta />
      </AllResourcesContainer>
    </>
  );
};

export default AllResources;

// Below is the part of the prev code for the search feature before the addition of Algolia

// const SearchInterface = ({
//   categories,
//   titleSearch,
//   handleTitleSearch,
//   selectedCategory,
//   handleCategorySelection
// }: {
//   categories: Tag[];
//   titleSearch: string;
//   handleTitleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
//   selectedCategory: string;
//   handleCategorySelection: (e: React.ChangeEvent<HTMLSelectElement>) => void;
// }) => {
//   return (
//     <Form>
//       <Row>
//         <Col>
//           <Form.Label>Sort By</Form.Label>
//         </Col>
//         <Col>
//           <Form.Label visuallyHidden>Select Category</Form.Label>
//           {Array.isArray(categories) ? (
//             <Form.Select
//               aria-label="Select Category"
//               value={selectedCategory}
//               onChange={handleCategorySelection}>
//               <option>{CATNAMES.ALL}</option>
//               {categories.map((c, i) => {
//                 return <option key={c.slug + i}>{c.name}</option>;
//               })}
//             </Form.Select>
//           ) : null}
//         </Col>
//         <Col>
//           <Form.Label visuallyHidden>Search Titles</Form.Label>
//           <Form.Control
//             type="text"
//             value={titleSearch}
//             onChange={handleTitleSearch}
//             placeholder="Search Titles"
//           />
//         </Col>
//       </Row>
//       <Row></Row>
//     </Form>
//   );
// };

const StyledSearchResultLink = styled(WmkLink)`
  color: ${colors.text.hex};
  padding: 0.25rem 0;
  display: block;
  transition: all 0.3s ease;
  &:hover {
    text-decoration: none;
    color: ${colors.techNavGreen.hex};
    transition: all 0.3s ease;
  }
`;

const SearchResult = ({
  result,
  index,
  mobile
}: {
  result: PdfResult | VideoResult | TaggedPdfResult | TaggedVideoResult;
  index: number;
  mobile: boolean;
}) => {
  return (
    <>
      {!mobile && (
        <Col
          lg={1}
          sm={2}
          style={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: getTableBg(index - 1)
          }}>
          <ResultIcon model={result.nodeType} />
        </Col>
      )}
      <Col
        lg={mobile ? 12 : 11}
        sm={mobile ? 12 : 10}
        style={{
          backgroundColor: getTableBg(index - 1)
        }}>
        <StyledSearchResultLink
          target="blank"
          to={
            "slug" in result.node
              ? `/docs/${result.node.slug}`
              : `https://www.youtube.com/watch?v=${result.node.videoId}`
          }>
          {result.node.title}
        </StyledSearchResultLink>
      </Col>
    </>
  );
};

const SearchResultsHeading = ({ displayTitle }: { displayTitle: boolean }) => (
  <Col
    xs={12}
    style={{
      backgroundColor: colors.lightBlue.hex
    }}>
    <Row
      style={{
        textTransform: "uppercase",
        fontSize: `.9rem`
      }}>
      {displayTitle && (
        <Col lg={1} sm={2} style={{ padding: `.25rem`, textAlign: "center" }}>
          File Type
        </Col>
      )}
      <Col lg={11} sm={10} style={{ padding: `.25rem` }}>
        Title
      </Col>
    </Row>
  </Col>
);

const SearchResults = ({
  results,
  mobile
}: {
  results: IndexedSearch[];
  mobile: boolean;
}) => {
  return (
    <Row style={{ padding: `2rem 0` }}>
      <SearchResultsHeading displayTitle={mobile ? false : true} />
      {results.map((r, i) => (
        <SearchResult
          key={r.node.title + i}
          result={r as VideoResult | PdfResult}
          index={i}
          mobile={mobile}
        />
      ))}
    </Row>
  );
};

const CuratedDisplay = ({
  selectedCategory,
  categories,
  hash,
  mobile
}: {
  selectedCategory: string;
  categories: Tag[];
  hash: {
    [key: string]: { name: string; slug: string; list: TaggedIndexedSearch[] };
  };
  mobile: boolean;
}) => {
  let documents: TaggedIndexedSearch[] = [];
  let matchedResultCounter = 0;
  // const filteredHash = categories.reduce((acc, category) => {});
  return (
    <Row
      style={{
        padding: `0 0 2rem 0`,
        marginRight: 0,
        marginLeft: 0,
        justifyContent: "center"
      }}>
      {categories.map((cat, i) => {
        const results = hash[cat.slug];
        if (results && Array.isArray(results.list)) {
          return (
            <Row key={cat.name + i}>
              <Col xs={12} style={{ padding: `2rem 0 1rem 1rem` }}>
                <Type.H3 style={{ textTransform: "capitalize" }}>
                  {cat.name}
                </Type.H3>
              </Col>
              <SearchResultsHeading displayTitle={mobile ? false : true} />
              {results.list.map((r, j) => {
                // if (!documents.includes(r)) {
                //check if r.node.keywords includes cat.name
                if (r.node.keywords !== null && r.node.keywords !== undefined) {
                  if (r.node.keywords[0] === cat.name) {
                    // documents = documents.concat(r);
                    matchedResultCounter += 1;
                    return (
                      <SearchResult
                        key={cat.slug + j + r.node.title}
                        result={r as TaggedPdfResult | TaggedVideoResult}
                        index={matchedResultCounter}
                        mobile={mobile}
                      />
                    );
                  }
                } else {
                  //case when its video
                  if (
                    r.node.tags !== undefined &&
                    r.node.tags !== null &&
                    r.nodeType == "video"
                  ) {
                    //pick only first tag
                    if (r.node.tags[0].slug == cat.slug) {
                      matchedResultCounter += 1;
                      documents = documents.concat(r);
                      return (
                        <SearchResult
                          key={cat.slug + j + r.node.title}
                          result={r as TaggedPdfResult | TaggedVideoResult}
                          index={matchedResultCounter}
                          mobile={mobile}
                        />
                      );
                    }
                  }
                }
                // } else {
                //   documents = documents.concat(r);
                //   return <></>;
                // }
              })}
            </Row>
          );
        }
        return <></>;
      })}
    </Row>
  );
};

// const CategoryResult = ({
//   selectedCategory,
//   thisCategory
// }: {
//   selectedCategory: string;
//   thisCategory: string;
// }) => {
//   return selectedCategory === CATNAMES.ALL ||
//     selectedCategory === thisCategory ? (
//     <>cat result</>
//   ) : null;
// };

const query = graphql`
  {
    pdf: allContentfulDocument(
      filter: { file: { filename: { regex: "/pdf$/" } } }
    ) {
      edges {
        node {
          ...NodeDocumentFields
        }
      }
    }
    video: allYoutubeVideo {
      edges {
        node {
          title
          videoId
          localThumbnail {
            childImageSharp {
              gatsbyImageData
            }
          }
          description
        }
      }
    }
    site {
      ...SiteMetadataFields
    }
    page: contentfulPage(slug: { eq: "technical-resources" }) {
      ...NodePageFields
      ...PageBlockImageCopy
      ...PageBlockIconCopy
      ...PageBlockIconCard
      ...PageBlockTestimonial
      ...PageBlockColumns
    }
  }
`;
