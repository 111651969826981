import * as React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { HiOutlineX, HiSearch, HiLink } from "react-icons/hi";
import {
  BsFileEarmarkPdf,
  BsFileEarmarkPlay,
  BsFileEarmark,
  BsGlobe2,
  BsFileEarmarkText
} from "react-icons/bs";
import styled from "styled-components";

import { Bg } from "../../../calculators/kind/LCCA/Locked";
import { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { WmkLink } from "wmk-link";
import { IndexedSearch, SearchIndexQuery, SearchIndex } from "wmk-search";
import { colors } from "../../../../vars/palette";

const StyledButton = styled.button<{ open?: boolean; mobile?: boolean }>`
  background: none;
  border: none;
  padding: 10px 10px;
  font-size: 30px;
  position: relative;
  z-index: 200;
  ${({ mobile }) => (mobile ? `margin-bottom: 1.5rem;` : ``)}
  svg {
    path {
      fill: ${({ open, mobile }) =>
        mobile
          ? colors.white.hex
          : open
          ? colors.darkGray.hex
          : colors.black.hex};
    }
  }
`;

interface ResultBaseNode {
  title: string;
  slug: string;
  parentPath: string;
}
interface ResultResource {
  nodeType: "resource";
  node: ResultBaseNode;
}

interface ResultPage {
  nodeType: "page";
  node: ResultBaseNode;
}

interface ResultNews {
  nodeType: "news";
  node: ResultBaseNode;
}

interface ResultPdf {
  nodeType: "pdf";
  node: {
    title: string;
    file: {
      url: string;
    };
    description: string;
  };
}

const query = graphql`
  {
    resource: allContentfulResource {
      edges {
        node {
          title
          slug
          parentPath
        }
      }
    }
    page: allContentfulPage(filter: { title: { ne: "Conference" } }) {
      edges {
        node {
          title
          slug
          parentPath
        }
      }
    }
    news: allContentfulNews {
      edges {
        node {
          title
          slug
        }
      }
    }
    pdf: allContentfulAsset(
      filter: { file: { contentType: { regex: "/pdf/" } } }
    ) {
      edges {
        node {
          title
          file {
            url
          }
          description
        }
      }
    }
  }
`;

const SearchCol = styled(Col)`
  a {
    display: flex;
    align-items: center;
    color: ${colors.text.hex};
    text-decoration: none;
    &:hover {
      color: ${colors.blue.hex};
      text-decoration: none;
    }
  }
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
`;

const IconWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    margin-right: 1rem;
    transform: scale(1.1);
  }
  .globe {
    position: absolute;
    transform: scale(0.5);
    left: -0.5px;
    top: 1px;
  }
`;
export const ResultIcon = ({
  model,
  size = "1.4rem"
}: {
  model: "pdf" | "resource" | "news" | "page" | "video";
  size?: string | number;
}) =>
  model === "pdf" ? (
    <IconWrap>
      <BsFileEarmarkPdf size={size} title="PDF Document" />
    </IconWrap>
  ) : model === "page" || model === "resource" ? (
    <IconWrap title="Webpage">
      <BsFileEarmark className="earmark" size={size} />
      <BsGlobe2 className="globe" size={size} />
    </IconWrap>
  ) : model === "news" ? (
    <IconWrap>
      <BsFileEarmarkText size={size} title="News Article" />
    </IconWrap>
  ) : model === "video" ? (
    <IconWrap>
      <BsFileEarmarkPlay size={size} title="Video" />
    </IconWrap>
  ) : (
    <IconWrap>
      <HiLink size={size} title="Link" />
    </IconWrap>
  );

const getSearchLink = (
  result: ResultNews | ResultPage | ResultPdf | ResultResource
) => {
  const parentPath = "file" in result.node ? "" : result.node.parentPath;
  const slug = "file" in result.node ? "" : result.node.slug;
  const url = "file" in result.node ? result.node.file.url : undefined;
  const model = result.nodeType;
  const to =
    model === "pdf"
      ? url
      : `${
          model === "resource"
            ? "/technical-resources"
            : model === "news"
            ? "/news/news-and-commentary"
            : ""
        }${parentPath === "/" ? "" : parentPath}/${slug}`;
  const target: "blank" = model === "pdf" ? "blank" : undefined;
  const text = result.node.title;
  const Icon = <ResultIcon model={model} />; //(model, "1.4rem");
  return { to, target, text, Icon };
};

const SearchDrawer = ({ handler }: { handler: () => void }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const searchData: SearchIndexQuery = useStaticQuery(query);
  const dipraIndex = new SearchIndex(searchData);

  const SearchResults = ({ results }: { results: IndexedSearch[] }) => {
    results.sort((a, b) => a.node.title.localeCompare(b.node.title));
    return (
      <Container style={{ margin: 0, padding: 0 }}>
        <Row
          style={{
            width: `80vw`,
            padding: `0.5rem 2.1rem`,
            background: colors.white.hex,
            borderRadius: `0.25rem`,
            margin: 0,
            overflow: "scroll",
            maxHeight: `50vh`,
            flexWrap: "nowrap"
          }}
          className="flex-column">
          {results.length ? (
            results.map((result, i) => {
              const searchLink = getSearchLink(result as any);
              const to = searchLink.to;
              return (
                <SearchCol key={to + i}>
                  <WmkLink to={to} target={searchLink.target}>
                    {searchLink.Icon}
                    {searchLink.text}
                  </WmkLink>
                </SearchCol>
              );
            })
          ) : (
            <Col xs={12}>
              No matches for <strong>{searchQuery}</strong>. Please try another
              search
            </Col>
          )}
        </Row>
      </Container>
    );
  };

  const getSearchResults = (queryString) => {
    if (queryString === "") {
      setSearchResults([]);
    } else {
      setSearchResults(
        dipraIndex.index.reduce((results, searchItem) => {
          const regex = new RegExp(queryString, "ig");
          const evaluation = searchItem.node.title.match(regex);

          if (evaluation) {
            results.push(searchItem);
          }
          return results;
        }, [])
      );
    }
  };

  const handleSearchQuery = (e) => {
    setSearchQuery(() => {
      const queryString = e.target.value;
      getSearchResults(queryString);
      return queryString;
    });
  };

  const handleSearchExit = () => {
    if (searchQuery === "") {
      handler();
    } else {
      setSearchQuery("");
    }
  };

  return (
    <div
      style={{
        position: `fixed`,
        zIndex: 100,
        height: `100vh`,
        width: `100vw`,
        top: 0,
        left: 0,
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center"
      }}>
      <Bg />
      <div
        style={{
          position: "relative",
          zIndex: 300,
          top: `30vh`,
          width: "80vw"
        }}>
        <Form
          onSubmit={(e) => e.preventDefault()}
          style={{ position: "relative" }}>
          <Form.Label htmlFor="searchInput" style={{ display: `none` }}>
            Enter Search Query
          </Form.Label>
          <HiSearch
            style={{ position: "absolute", top: `32%`, left: `1.5rem` }}
          />
          <StyledButton
            style={{
              position: "absolute",
              top: `-12%`,
              right: `1rem`,
              fontSize: `1.25rem`
            }}
            onClick={handleSearchExit}>
            <HiOutlineX />
          </StyledButton>
          <Form.Control
            id="searchInput"
            placeholder=""
            style={{ padding: `.6rem 3rem` }}
            value={searchQuery}
            onChange={handleSearchQuery}
          />
        </Form>
        {searchQuery !== "" ? <SearchResults results={searchResults} /> : null}
      </div>
    </div>
  );
};

const CustomSearchIcon = ({
  height = 26,
  width
}: {
  height?: number;
  width?: number;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={height}
      height={width ? width : height}
      viewBox="0 0 47 47">
      <g transform="translate(2 2)">
        <path
          d="M23.589,2.5h0A21.095,21.095,0,0,1,31.8,43.021,21.094,21.094,0,0,1,15.38,4.158,20.957,20.957,0,0,1,23.59,2.5Zm0,38.216a17.126,17.126,0,0,0,0-34.252h0a17.126,17.126,0,0,0,0,34.252Z"
          transform="translate(-4.5 -4.5)"
          fill="#394a58"
        />
        <path
          d="M35.46,37.46a1.994,1.994,0,0,1-1.414-.586L23.561,26.389a2,2,0,1,1,2.828-2.828L36.874,34.046A2,2,0,0,1,35.46,37.46Z"
          transform="translate(7.54 7.54)"
          fill="#394a58"
        />
      </g>
    </svg>
  );
};

export const SearchButton = ({ toggle, handler, mobile }) => {
  return (
    <>
      <StyledButton
        name="search"
        open={toggle}
        onClick={handler}
        mobile={mobile}>
        <CustomSearchIcon />
      </StyledButton>
      {toggle ? <SearchDrawer handler={handler} /> : null}
    </>
  );
};
