import get from "lodash/get";
import { Img } from "wmk-image";
import { PageFields } from "../fragments/NodePageFields";
import { Img as OldImg } from "./Img";
// import { RichText } from "./RichText";
import { RichText } from "wmk-rich-text";

/**
 * @function
 * @param {array} obj - array of keyed objects
 * @param {string} param - select key in object to be hash index
 * @returns {Object}
 */
export const getHashTable = (obj, param) =>
  obj && obj.length
    ? obj.reduce((hash, p) => {
        const slug = get(p, param);
        if (typeof slug !== "undefined") {
          hash[slug] = p;
        }
        return hash;
      }, {})
    : {};

export class Page {
  title: string;
  parentPath: string;
  slug: string;
  meta: string;
  content: RichText;
  featuredImage: OldImg;
  secondaryImage: OldImg;
  ogImage: string;
  twitterImage: string;
  image: Img;
  imageAlt: Img;
  intro: { headline: string; subtext: string; subhead: string };
  constructor(node: PageFields) {
    const _node = { ...node };
    // const content = get(node, `content`);
    const content = _node.content ? new RichText(_node.content) : null;
    const image = new Img(_node.featuredImage);
    image.addCrop("ogImage", _node.featuredImage?.ogImage);
    image.addCrop("twitterImage", _node.featuredImage?.ogImage);
    image.addCrop("rollover", _node.featuredImage?.rollover);
    this.title = _node.title;
    this.parentPath = _node.parentPath;
    this.slug = _node.slug;
    this.meta = this.meta = _node.meta?.description
    ? _node.meta?.description
    : content ? content.excerpt(155) : _node.subhead;
    this.content = content ? content : undefined;
    this.featuredImage = new OldImg(_node.featuredImage);
    this.secondaryImage = new OldImg(_node.secondaryImage);
    this.ogImage = _node.ogImage?.gatsbyImageData?.images.fallback.src;
    this.twitterImage = 
      _node.twitterImage?.gatsbyImageData?.images.fallback.src;
    this.image = image;
    this.intro = {
      headline: _node.headline,
      subtext: _node.sub?.text,
      subhead: _node.subhead
    };
  }
}

export class Pages {
  /**
   */
  list: Page[];
  length: number;
  slugHash: { [key: string]: Page[] };
  constructor(edges: { node: PageFields }[]) {
    const _edges = [...edges];
    const pgs = Array.isArray(_edges) ? _edges : [];
    const pages = pgs.map((edge) => {
      const newPage = new Page(edge.node);
      return newPage;
    });
    this.list = pages;
    this.length = pages.length;
    this.slugHash = getHashTable(pages, `slug`);
  }
  /**
   *
   */
  matchParent(slug: string) {
    const all = this.list;
    return all.reduce((match, pg) => {
      if (pg.parentPath === slug) {
        match.push(pg);
      }
      return match;
    }, []);
  }
  /**
   *
   */
  append(pgs: Page) {
    if (pgs instanceof Pages && get(pgs, `pages[0]`) instanceof Page) {
      this.list = this.list.concat(pgs.list);
      this.slugHash = getHashTable(this.list, `slug`);
      this.length++;
    }
    return this.list;
  }
  /**
   *
   */
  matchSlug(slug: string) {
    return get(this, `slugHash[${slug}]`);
  }
}
